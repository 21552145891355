const configuration = new Map([
  [
    'lab', {
      ASSET_BASE: 'https://lab-doc-pipeline-assets-store-assetsstore-3nrss39lr0hw.s3.amazonaws.com',
      DOCS_BASE: 'https://lab-docs-assets.d.cjpowered.com',
      OAUTH_CLIENT_ID: 'qPlpkxS3byepVkyLAlD3epIX36VB7Odh',
      PAT_SERVICE_BASE: 'https://platform-service-proxy.lab.cj.com',
    },
  ],
  [
    'production', {
      ASSET_BASE: 'https://production-doc-pipeline-assets-store-assetsstore-1q89u4n9t6xlw.s3.amazonaws.com',
      DOCS_BASE: 'https://production-docs-assets.p.cjpowered.com',
      OAUTH_CLIENT_ID: 'NMXnQOp6Qri3AQP5S8IYLHtrkZaKLySA',
      PAT_SERVICE_BASE: 'https://id.api.cj.com',
    },
  ],
]);

const getEnvironment = () =>
  window.location.origin === 'https://developers.cj.com'
    ? 'production'
    : 'lab';

export const getConfig = (key) =>
  configuration.get(getEnvironment())[key];
